<template>
  <div class="main-parlay">
    <ParlayPremium v-if="isPremiumUser" />
    <ParlayFree v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Main',
  components: {
    ParlayFree: () => import('@/components/Sections/GolTipster-v2/General/ParlayFree'),
    ParlayPremium: () => import('@/components/Sections/GolTipster-v2/Parlay/ParlayPremium'),
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'isLoggedIn']),
    isPremiumUser() {
      return this.isLoggedIn && this.getPremiumAccount;
    },
  },
};
</script>

<style scoped lang="scss"></style>
